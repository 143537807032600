// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import './satoshi.css';

* {
    font-family: 'Satoshi-Variable'!important;
}

.font-bold {
    font-weight: bold;
}

.jc-beetween {
    justify-content: space-between;
}

.d-flex {
    display: flex;
}

.text-color {
    color: #000;
}

.mb-0 {
    margin-bottom: 0;
}

.gap-5 {
    gap: 10px;
}

.header-container {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
}

.card {
    border: none;
}

.m-0 {
    margin: 0;
}

.icon {
    width: 50px;
    height: 50px;
}

.text-left-important {
    text-align: left!important;
}

.mt-10 {
    margin-top: 10px!important;
}

.text-blod-description {
    font-size: 20px!important;
}

.text-blod-small-description {
    font-size: 18px!important;
}

.text-gray-small {
    color: rgb(185,188,194)!important;
    font-size: 18px!important;
}

.cursor-pointer {
    cursor: pointer;
}
.padding-text-part {
    padding: 35px 60px;
}

.border-2px-solid-E6EAEE {
    border: 2px solid #E6EAEE;
    &:hover {
        border-color: #33AF88;
        .icon_service {
            .worklist_index{
                background: #33AF88!important;
            }
        }
    }
}

.d-flex-column-20 {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.text-color-1E9963 {
    color: #1E9963!important;
}

.accordion {
    &.custom_item {
        width: calc(50% - 20px);
        @media screen and (max-width: 1200px) {
            width: 100%
        }
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
        .card {
            width: 100%;
            border: 1px solid #E6EAEE;
            border-radius: 10px;
            .card-header {
                background-color: transparent;
                border: none;
                padding: 0 1.25rem;
            }
            .card-body {
                padding: 0 2rem 0.75rem 2rem;
            }
        }
           
        .btn-link:hover {
            color: none!important;
            text-decoration: none!important;
        }
        .btn-link:focus{
            text-decoration: none!important;
        }
        .btn:focus {
            box-shadow: none;
        }
        button:focus {
            border-color: transparent!important;
        }
    }
}

.gap-20 {
    gap: 20px;
}
.mt-50 {
    margin-top: 50px;
}

a.btn-default {
    &.custom {
        background: #E8F5EF;
        border-color: #1E9963;
        color: #1E9963;
    }
}
button.btn-default-green {
    &.custom {
        background: #E8F5EF;
        border-color: #1E9963;
        color: #1E9963;
    }
}
.custom_back_ground {
    background-color: #e8f5f2;
}

.mt-30 {
    margin-top: 30px!important;
}

.input-group-text {
    background-color: #FFF;
}

.background-white-important {
    background-color: #FFF;
}

.contact-form--1 input {
    background-color: #FFF!important;
}

.active-dark .contact-form--1 textarea {
    background-color: #FFF!important;
}

.form-control.is-invalid:focus {
    box-shadow: none!important;
}

.form-control:focus {
    box-shadow: none!important;
}

.form-control {
    border: 1px solid #ced4da!important;
    border-left: 0px!important;
    border-radius: .25rem;
}

textarea.form-control {
    border: 1px solid #ced4da!important;
}

.slider-paralax {
    padding-top: 0!important;
}

.display_on_mobile {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.position-relative {
    position: relative;
}
.chevron.checked {
    &::after {
        transform: translateY(-30%) rotate(-45deg);   
    }
}
.chevron {
    transition: all 0.3s ease-in-out;
}

.chevron::after {
	border-style: solid;
	border-width: 2px 2px 0 0;
    color: #A1A6AF;
	content: '';
	display: inline-block;
	width: 14px;
	height: 14px;
	position: absolute;
	top: 50%;
    right: 0;
	transform: translateY(-75%) rotate(135deg);

	vertical-align: top;
}

.pt-100 {
    padding-top: 100px;
}

.Kanit {
    font-family: "Kanit"!important;
}

.pb--80 {
    padding-bottom: 80px;

    @media screen and (max-width: 1200px) {
        padding-bottom: 0px;    
    }
}